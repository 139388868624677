.App__updateing {
    position: absolute;
    top: 0;
    width: 100%;
    background: rgb(138, 173, 54);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    padding: 10px;
    padding-top: 4.5rem;
}

.App__updateFail {
    background: crimson;
}