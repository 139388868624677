.odontogram__tutorialModal {
    --height: 73%;
    align-items: flex-end;
}

.tutorial-slide {
    flex-direction: column;
    padding: 20px;
    justify-content: flex-end;
    padding-bottom: 4rem;
    padding-bottom: calc(4rem + env(safe-area-inset-bottom));
}

.tutorial-slide__button {
    width: 100%;
}

.tutorial-slide h1 {
    font-size:1.8rem;
    text-align:center;
    color: #263446;
    font-weight:600;
    margin:0 auto;
}

.tutorial-slide p {
    font-size:1.4rem;
    text-align:center;
    color:#88949E;
    font-weight:400;
    margin: 1rem auto 0 auto;
}

.tutorial-slide img {
    height: 53%;
    margin: 5%;
    max-height: 400px;
    outline: 2px solid white;
    outline-offset: -1px;
}

.modal-wrapper {
    border-top-left-radius: 2rem !important;
    border-top-right-radius: 2rem !important;
    overflow: hidden !important ;
}

@media (min-width:375px) and (min-height:600px){
    .odontogram__tutorialModal {
        --height: 70%;
    }

    .tutorial-slide h1 {
        font-size:2.5rem;
    }

    .tutorial-slide p {

    }
}

@media (min-width:411px){
    .tutorial-slide h1 {
        font-weight: 700;
    }

    .tutorial-slide p {
        
    }       
}

ion-slides {
    height: 100%;
    width: 100%;
}